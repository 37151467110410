import axios from 'axios'
import { AuthModel, UserModel } from './_models'

const API_URL = process.env.REACT_APP_API_URL

const API_URL_NEW = 'https://digitalfactory.co.in/lc_app/api' // PRODUCTION API
//const API_URL_NEW = 'https://digitalfactory.co.in/lc_app_test/api' // TESTING API

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL_OLD = `${API_URL}/forgot_password`

export const REQUEST_PASSWORD_URL = `${API_URL_NEW}/forgot_password`
export const LOGIN_URL_NEW = `${API_URL_NEW}/login`
export const OTP_VALIDATE_URL = `${API_URL_NEW}/validate`
export const GET_USER_BY_ACCESSTOKEN_URL_NEW = `${API_URL_NEW}/verify_token`
export const ONBOARDING_URL = `${API_URL_NEW}/onboarding`
export const MEMBERS_URL = `${API_URL_NEW}/members`
export const PROFILE_URL = `${API_URL_NEW}/profile`
export const FEED_URL = `${API_URL_NEW}/feed`
export const FEED_GET_URL = `${API_URL_NEW}/feed_data`
export const CHANNEL_URL = `${API_URL_NEW}/channel_get_details`
export const CHANNEL_REQUEST_URL = `${API_URL_NEW}/channel_join`
export const PROFILE_CHECK_URL = `${API_URL_NEW}/profile_check`
export const ARTICLE_URL = `${API_URL_NEW}/articles`


//Team//
export const CHANNEL_POST_URL = `${API_URL_NEW}/channel_create`
export const ALL_MEMBERS_GET_URL = `${API_URL_NEW}/admin_members`
export const CHANNEL_GET_URL = `${API_URL_NEW}/channel_get_details`
export const MEMBERS_QUESTION_GET_URL = `${API_URL_NEW}/members_questions`
export const MEMBERS_VIEW_ANSWERS_GET_URL = `${API_URL_NEW}/members_questions`
export const EVENT_GET_URL = `${API_URL_NEW}/events`
//Team//


// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL_NEW, {
    email,
    password,
  })
}

export function ProfileUpdates(
  formData: FormData,
  fName: string,
  lName: string,
  company: string,
  contactEmail: string,
  contactPhone: string,
  country: string,
  country_zipcode: string,
  timeZone: string,
  comm_email: boolean,
  comm_phone: boolean,
  user_bio:string,
  state:string,
  city:string
) {
  formData.append('fName', fName);
  formData.append('lName', lName);
  formData.append('company', company);
  formData.append('contactEmail', contactEmail);
  formData.append('contactPhone', contactPhone);
  formData.append('country', country);
  formData.append('country_zipcode', country_zipcode);
  formData.append('timeZone', timeZone);
  formData.append('comm_email', comm_email.toString());
  formData.append('comm_phone', comm_phone.toString());
  formData.append('user_bio', user_bio.toString());
  formData.append('state', state.toString());
  formData.append('city', city.toString());
  return axios.post<AuthModel>(PROFILE_URL, formData, {
    headers: {
      'Content-Type': 'multipart/form-data', // Important: Set the correct content type for file uploads
    },
  });
}



export function ProfileCreate(
  formData: FormData,
  fName: string,
  lName: string,
  company: string,
  contactEmail: string,
  contactPhone: string,
  country: string,
  country_state: string,
  country_city: string,
  country_zipcode: string,
  timeZone: string,
) {
  formData.append('fName', fName);
  formData.append('lName', lName);
  formData.append('company', company);
  formData.append('contactEmail', contactEmail);
  formData.append('contactPhone', contactPhone);
  formData.append('country', country);
  formData.append('country_state', country_state);
  formData.append('country_city', country_city);
  formData.append('country_zipcode', country_zipcode);
  formData.append('timeZone', timeZone);

  return axios.post(ALL_MEMBERS_GET_URL, formData, {
    headers: {
      'Content-Type': 'multipart/form-data', // Important: Set the correct content type for file uploads
    },
  });
}
// Server should return AuthModel
export function profilestatusCheck(email_check: string) {
  return axios.post(PROFILE_CHECK_URL, {
    email_check
  })
}


export function FeedPost(
  formData: FormData,
  feeddata: string,
  username: string,
  userid: number,
  channel_id: string | null = null

) {
  formData.append('feed_data', feeddata);
  formData.append('postby', username);

  formData.append('postuid', userid.toString()); // Convert userid to string before appending
  formData.append('channel_id', channel_id || '');
  return axios.post(FEED_URL, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}



export function getMembers(get_all: string, offset: number, limit: number,searchTerm:string) {
  return axios.post(MEMBERS_URL, {
    get_all,
    offset,
    limit,
    searchTerm
  })
}

export function ChannelJoin(user_id: number, channel_id: number) {
  return axios.post(CHANNEL_REQUEST_URL, {
    user_id,
    channel_id
  })
}

export function getFeed(get_all: string) {
  return axios.post(FEED_GET_URL, {
    get_all
  })
}

export function deleteFeed(feed_del_id: string) {
  return axios.post(FEED_URL, {
    feed_del_id
  })
}


export function commentFeed(fc_feed_id: string,fc_comments:string,fc_member_id:number) {
  return axios.post(FEED_URL, {
    fc_feed_id,fc_comments,fc_member_id
  })
}

export function feedCommandReply(fcr_id: string,fcr_userid:number,fcr_comments:string) {
  return axios.post(FEED_URL, {
    fcr_id,fcr_userid,fcr_comments
  })
}

export function feedCommandInnerReply(fcir_id: string,fcir_userid:number,fcir_comments:string) {
  return axios.post(FEED_URL, {
    fcir_id,fcir_userid,fcir_comments
  })
}


export function feedLike(fl_feed_id: string,fl_user_id:number,status:any) {
  return axios.post(FEED_URL, {
    fl_feed_id,fl_user_id,status
  })
}

export function feedCommentDelete(fcm_id: string,fcm_user_id:number,fcm_feed_id:number) {
  return axios.post(FEED_URL, {
    fcm_id,fcm_user_id,fcm_feed_id
  })
}


export function feedReplyCommentDelete(fcr_del_id: string,fcr_del_user_id:number,fcr_del_comment_id:number) {
  return axios.post(FEED_URL, {
    fcr_del_id,fcr_del_user_id,fcr_del_comment_id
  })
}

export function channels(get_channel: string) {
  return axios.get(CHANNEL_URL, {
    params: {
      get_channel: get_channel
    }
  });
}

export function channel(get_channel_id: string, user_id: number) {
  return axios.get(CHANNEL_URL, {
    params: {
      get_channel_id: get_channel_id,
      user_id: user_id
    }
  });
}

export function articles(get_articles: string) {
  return axios.get(ARTICLE_URL, {
    params: {
      get_articles: get_articles
    }
  });
}

export function getMembersProfile(profile_id: number) {
  return axios.post(MEMBERS_URL, {
    profile_id
  })
}


// Server should return AuthModel
export function questionare(question1: string[], question2: string[], question3: string, question4: string,
  question5: string, question6: string, question7: string, question8: string, question9: string, userauthemail: string) {
  return axios.post<AuthModel>(ONBOARDING_URL, {
    question1,
    question2,
    question3,
    question4,
    question5,
    question6,
    question7,
    question8,
    question9,
    userauthemail
  })
}


// Server should return AuthModel
export function register(
  email: string,
  //password: string,
  //password_confirmation: string
) {
  return axios.post(OTP_VALIDATE_URL, {
    email,
    // password,
    // password_confirmation,
  })
}

// Server should return AuthModel
export function registerPassword(
  password: string,
  password_confirmation: string,
  user_email: string
) {
  return axios.post(OTP_VALIDATE_URL, {
    password,
    password_confirmation,
    user_email
  })
}

export function resetPassword(
  password: string,
  password_confirmation: string,
  user_email: string
) {
  return axios.post(REQUEST_PASSWORD_URL, {
    password,
    password_confirmation,
    user_email
  })
}


export function updateNewPassword(
  current_password: string,
  new_password: string,
  user_email: string
) {
  return axios.post(PROFILE_URL, {
    current_password,
    new_password,
    user_email
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function requestOTP(
  otp: string,
  lc_email: string,
) {
  return axios.post(OTP_VALIDATE_URL, {
    otp,
    lc_email
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL_NEW, {
    api_token: token,
  })
}



export function getEventDetails(events_view_id: string, user_view_id: number) {
  return axios.get(EVENT_GET_URL, {
    params: {
      events_view_id: events_view_id,
      user_view_id: user_view_id
    },
  });
}

export function confirmEvent(event_confirm: string, event_confirm_user: any) {
  return axios.post(EVENT_GET_URL, {
    event_confirm: event_confirm,
    event_confirm_user: event_confirm_user,
  })
}

export function channel_create(
  formData: FormData,
  channel_name: string,
  channel_description: string,
  channel_category: string,
  // channel_image: File | '',
  access_level: string,
  visibility_setting: string,
  posting_permission: string,
  // general_settings: string[],
  active_lock_screen: boolean,
  // created_by: string,
) {
  formData.append('channel_name', channel_name);
  formData.append('channel_description', channel_description);
  formData.append('channel_category', channel_category);
  formData.append('active_lock_screen', String(active_lock_screen));
  formData.append('access_level', access_level);
  formData.append('visibility_setting', visibility_setting);
  formData.append('posting_permission', posting_permission);
  return axios.post(CHANNEL_POST_URL, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function all_members(getall: string
) {
  return axios.get(ALL_MEMBERS_GET_URL, {
    params: {
      getall: getall
    },
  });
}

export function members_question(getall: string
) {
  return axios.get(MEMBERS_QUESTION_GET_URL, {
    params: {
      get_questions: getall
    },
  });
}

export function members_view_answers(userId: number) {
  return axios.get(MEMBERS_VIEW_ANSWERS_GET_URL, {
    params: {
      get_questions_id: userId
    },
  });
}

export function events_post_data(
  formData: FormData,
  event_name: string,
  event_time_zone: string,
  event_pricing: string,
  event_start_date: string,
  event_start_time: string,
  event_end_date: string,
  event_end_time: string,
  event_location: string,
  onlineEventUrl: string,
  event_description: string,
  event_lockscreen: boolean,
  event_custom_url: string,
) {
  formData.append('event_name', event_name);
  formData.append('event_time_zone', event_time_zone);
  formData.append('event_pricing', event_pricing);
  formData.append('event_start_date', event_start_date);
  formData.append('event_start_time', event_start_time);
  formData.append('event_end_date', event_end_date);
  formData.append('event_end_time', event_end_time);
  formData.append('event_location', event_location);
  formData.append('onlineEventUrl', onlineEventUrl);
  formData.append('event_description', event_description);
  formData.append('event_lockscreen', String(event_lockscreen));
  formData.append('event_custom_url', event_custom_url);
  return axios.post(EVENT_GET_URL, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function Events_Update (
  formData:FormData,
  event_id_upd: string,
  event_name_upd: string,
  event_time_zone_upd: string,
  event_pricing_upd: string,
  event_start_date_upd: string,
  event_start_time_upd: string,
  event_end_date_upd: string,
  event_end_time_upd: string,
  event_location_upd: string,
  onlineEventUrl_upd: string,
  event_description_upd: string,
  // event_lockscreen_upd: boolean,
  // event_custom_url_upd: string,
){
  formData.append('event_id_upd', event_id_upd);
  formData.append('event_name_upd', event_name_upd);
  formData.append('event_time_zone_upd', event_time_zone_upd);
  formData.append('event_pricing_upd', event_pricing_upd);
  formData.append('event_start_date_upd', event_start_date_upd);
  formData.append('event_start_time_upd', event_start_time_upd);
  formData.append('event_end_date_upd', event_end_date_upd);
  formData.append('event_end_time_upd', event_end_time_upd);
  formData.append('event_location_upd', event_location_upd);
  formData.append('onlineEventUrl_upd', onlineEventUrl_upd);
  formData.append('event_description_upd', event_description_upd);
  // formData.append('event_lockscreen', String(event_lockscreen_upd));
  // formData.append('event_custom_url', event_custom_url_upd);
  return axios.post(EVENT_GET_URL, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}
export function events_get_data(events: string
) {
  return axios.get(EVENT_GET_URL, {
    params: {
      events: events
    },
  });
}

export function events_rsvp_data(event_rsvp_id: string
) {
  return axios.get(EVENT_GET_URL, {
    params: {
      event_rsvp_id: event_rsvp_id
    },
  });
}

export function events_delete(event_del_id: string) {
  return axios.post(EVENT_GET_URL, {
    event_del_id: event_del_id
  })
}

export function pastEvents_get_data(events: string
) {
  return axios.get(EVENT_GET_URL, {
    params: {
      events: events
    },
  });
}

export function Events_edit_data(events_edit_id: string
) {
  return axios.get(EVENT_GET_URL, {
    params: {
      events_edit_id: events_edit_id
    },
  });
}

export function Events_publish_data(event_status_id: string, event_status_upd: string
) {
  return axios.post(EVENT_GET_URL, {
    event_status_id: event_status_id,
    event_status_upd: event_status_upd
  });
}

export function ArticlesCreate(
  formData: FormData,
  title: string,
  description: string,
  visibility_settings: string,
) {
  formData.append('title', title);
  formData.append('description', description);
  formData.append('visibility_settings', visibility_settings);
  return axios.post<AuthModel>(ARTICLE_URL, formData, {
    headers: {
      'Content-Type': 'multipart/form-data', // Important: Set the correct content type for file uploads
    },
  });
}

export function Articles_get_data(get_articles: string
) {
  return axios.get(ARTICLE_URL, {
    params: {
      get_articles: get_articles
    },
  });
}

export function Articles_delete(article_del_id: string) {
  return axios.post(ARTICLE_URL, {
    article_del_id
  })
}

export function Articles_publish_data(article_id: string, article_status: string) {
  return axios.post(ARTICLE_URL, {
    article_id: article_id, article_status: article_status
  })
}

export function getArticleDetails(article_get_id: string) {
  return axios.get(ARTICLE_URL, {
    params: {
      article_get_id: article_get_id,
    },
  });
}

export function POST_Articles_Cate(article_cat_title: string) {
  return axios.post(ARTICLE_URL, {
    article_cat_title
  })
}

export function GET_Articles_Cat(get_articles_cat: string) {
  return axios.get(ARTICLE_URL, {
    params: {
      get_articles_cat: get_articles_cat,
    },
  });
}

export function POST_Articles_Tags(article_tag_title: string) {
  return axios.post(ARTICLE_URL, {
    article_tag_title
  })
}

export function GET_Articles_Tags(get_articles_tags: string) {
  return axios.get(ARTICLE_URL, {
    params: {
      get_articles_tags: get_articles_tags,
    },
  });
}

export function DELETE_Articles_Cate(article_cat_id: string) {
  return axios.post(ARTICLE_URL, {
    article_cat_id
  })
}

export function DELETE_Articles_Tags(article_tag_id: string) {
  return axios.post(ARTICLE_URL, {
    article_tag_id
  })
}

export function Articles_Cat_Unpublish(article_cat_upd_id: string, article_cat_upd_status: string) {
  return axios.post(ARTICLE_URL, {
    article_cat_upd_id: article_cat_upd_id, article_cat_upd_status: article_cat_upd_status
  })
}

export function Articles_Tags_Unpublish(article_tag_upd_id: string, article_tag_upd_status: string) {
  return axios.post(ARTICLE_URL, {
    article_tag_upd_id: article_tag_upd_id, article_tag_upd_status: article_tag_upd_status
  })
}

export function Edit_Cat_Details(get_articles_cat: string) {
  return axios.get(ARTICLE_URL, {
    params: {
      get_articles_cat: get_articles_cat,
    },
  });
}

export function Edit_Tags_Details(get_articles_tags: string) {
  return axios.get(ARTICLE_URL, {
    params: {
      get_articles_tags: get_articles_tags,
    },
  });
}

export function Articles_Update_Category(article_cat_upd_id: string, article_cat_upd_title: string) {
  return axios.post(ARTICLE_URL, {
    article_cat_upd_id: article_cat_upd_id, article_cat_upd_title: article_cat_upd_title
  })
}

export function Articles_Update_Tags(article_tag_upd_id: string, article_tag_upd_title: string) {
  return axios.post(ARTICLE_URL, {
    article_tag_upd_id: article_tag_upd_id, article_tag_upd_title: article_tag_upd_title
  })
}

export function Articles_Update(
  formData: FormData,
  article_id_upd: string,
  article_title_upd: string,
  article_description_upd: string,
  article_visibility_upd: string,
) {
  formData.append('article_id_upd', article_id_upd);
  formData.append('article_title_upd', article_title_upd);
  formData.append('article_description_upd', article_description_upd);
  formData.append('article_visibility_upd', article_visibility_upd);
  return axios.post<AuthModel>(ARTICLE_URL, formData, {
    headers: {
      'Content-Type': 'multipart/form-data', // Important: Set the correct content type for file uploads
    },
  });
}





