/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {getUserByToken, register} from '../core/_requests'
import {Link} from 'react-router-dom'
import {useAuth} from '../core/Auth'
import axios from 'axios'
import { useNavigate } from "react-router-dom";
import { KTSVG } from '../../../../_metronic/helpers'
const initialValues = {
  email: '',
  acceptTerms: false,
}
const registrationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(100, 'Maximum 100 Characters Allowed')
    .required('Email is required'),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function Registration() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data} = await register(
          values.email
        )

        if(data=='Found')
        {
          navigate("/auth/otp")
          localStorage.setItem("lc_useremail", values.email);
        }
        else if(data=='Already Registered')
        {
        formik.setErrors({email:"Already Registered! Kindly Login to Access Dashboard"})
        }
        else if(data=='Not Found')
        {
          formik.setErrors({email:"User Not Found"});
        }


        //console.log('asdf',data);
        //saveAuth(auth)
       // saveAuth(auth)
      //  const {data: user} = await getUserByToken(auth.api_token)
       // setCurrentUser(user)
       setLoading(false)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The registration details is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const email_data = { 'email_id': formik.getFieldProps('email').value};
  const email_validate_url = 'https://digitalfactory.co.in/lc_app/api/validate';
  const [loading_email, setloading_email] = useState(false);
  const navigate = useNavigate();
  const onValidateUser = () => {
    if(formik.getFieldProps('email').value!="")
    {
    setloading_email(true)
     axios.post(email_validate_url, email_data)
        .then(response => {
            // console.log(response.data);
             setloading_email(false)
             response.data!='Not Found' ?  navigate("/otp") : formik.setErrors({email:"User Not Found"});
        })
        .catch(({response}) => {
           // console.log(response);
        })
      }
      else{
        formik.setErrors({email:"Email is required"})
      }
      }

  return (

    <>

<div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">


<div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg">
    <div className="modal-content">
      <div className="modal-header">
        <h3 className="modal-title">Privacy Policy (Member Only)</h3>
        <div
          className="btn btn-icon btn-sm btn-active-light-primary ms-2"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <KTSVG
            path="/media/icons/duotune/arrows/arr061.svg"
            className="svg-icon svg-icon-2x"
          />
        </div>
      </div>
      <div className="modal-body">
      <div className='row'>
<p className="fs-4">
All Members of the Leaders Club, collectively referred to as the 'Members,' hereby acknowledge and affirm their commitment to act in the best interest of the Leaders Club. In their capacity as Members, they shall diligently and responsibly perform their duties, promoting the overall well-being, growth, and success of the Leaders Club.
</p>
<p className="fs-4">
Each Member shall prioritize the leaders club interests above their personal or individual interests. They shall exercise their roles and responsibilities with the utmost care, integrity, and professionalism while demonstrating a high level of ethical conduct. Members shall avoid any conflicts of interest that may compromise the Leaders Club's objectives or undermine its reputation.
</p>
<p className="fs-4">
Furthermore, Members shall engage in open and transparent communication, fostering an environment of collaboration, trust, and mutual respect. They shall actively contribute to the Leaders Club's initiatives, sharing knowledge, insights, and experiences for the benefit of all stakeholders involved.
</p>
<p className="fs-4">
Members shall comply with all applicable laws, regulations, and guidelines, ensuring full legal compliance in all their actions and decisions related to the Leaders Club. They shall refrain from engaging in any activities that may cause harm or damage to the Leaders Club or its members.
In the event of disputes or disagreements, Members shall strive to resolve them amicably, utilizing appropriate conflict resolution mechanisms available within the Leaders Club. They shall prioritize the preservation of harmonious relationships, seeking consensus and common ground whenever possible.
</p>
<p className="fs-4">
This commitment to act in the best interest of the Leaders Club shall endure throughout the duration of each Member's participation in the Leaders Club. Members acknowledge that their actions and conduct reflect upon the Leaders Club as a whole, and they shall conduct themselves in a manner that upholds the Leaders Club's values, reputation, and objectives.
By accepting membership in the Leaders Club, each Member affirms their understanding of this commitment and agrees to abide by its principles. This commitment shall serve as a guiding principle for all Members, promoting a unified and purposeful approach toward achieving the Leaders Club's goals."
</p>
<p className="fs-4">
Please note that this response is provided for informational purposes only and should not be considered as legal advice. For specific legal matters related to your situation, it is recommended to consult with a qualified attorney.
</p>
<p className="fs-4">
Participants are free to use the information they receive, but they are prohibited from revealing the identity or affiliation of any speaker or participant, nor can they attribute any statements to a particular individual. This allows participants to speak more freely and openly without fear of reprisal or retaliation.The rule helps to ensure that sensitive or confidential information is not leaked to the public, while also allowing participants to benefit from the insights and expertise of others in the group.

</p>

<div className="mt-5 mb-20">
<h1 className='mb-5'>Leaders Club Confidential Agreement</h1>
<ul className="fs-4">
<li>You will not share your company's confidential information.</li>
<li>You will not discuss the exact context of our meetups outside the Leaders Club audience.</li>
<li>You can refer to any company's CXOs name with their approvals.</li>
<li>You will not take any pictures or screenshots of virtual meetups.</li>
<li>You will not share the context of any meeting on any social media.</li>
<li>You will not invite people without prior approval.</li>
<li>Any promotional material content is prohibited</li>
</ul>
</div></div>
      </div>

    </div>
  </div>
</div>
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
      autoComplete="off"
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder'>Sign Up</h1>
        {/* end::Title */}

        <div className='text-gray-500 fw-semibold fs-6'>Existing User Only</div>
      </div>
      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}
      {/* begin::Form group Email */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Email</label>
        <input
          placeholder='Enter your Email Address'
          type='email'
          id="user_email"
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-check form-check-inline'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <span>
            I accept the{' '}
            <span  data-bs-toggle="modal" style={{cursor:'pointer'}} data-bs-target="#staticBackdrop" className='link-primary'>
             Privacy Policy
            </span>
            .
          </span>
        </label>
        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.acceptTerms}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-success w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <div className='text-gray-500 text-center fw-semibold fs-6'>
        Already Registered?{' '}
        <Link to='/auth/login'>
           Login
        </Link>
      </div>

      </div>
      {/* end::Form group */}
    </form>
    </>
  )
}
